<template>
  <div>
    <b-container>
      <b-card class="card-about pt-4 mt-4">
        <h2
          class="text-center text-primary font-size-20 font-weight-bold position-relative main-header mb-5"
        >
          {{ $t("auth.connectWith") }}
        </h2>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form
            class="position-relative form-top p-4"
            @submit.prevent="handleSubmit(contactUs)"
            ref="form"
          >
            <b-row>
              <b-col md="6">
                <input-form
                  class="mb-3"
                  v-model="contact.subject"
                  :validate="'required'"
                  :placeholder="$t('auth.subject')"
                  :name="$t('auth.subject')"
                  type="text"
                  :label="$t('auth.subject')"
                />
              </b-col>
              <b-col md="6">
                <input-form
                  class="mb-3"
                  v-model="contact.email"
                  :validate="'required'"
                  :placeholder="$t('auth.email')"
                  :name="$t('auth.email')"
                  type="email"
                  :label="$t('auth.email')"
                />
              </b-col>
              <b-col md="12">
                <textarea
                  :placeholder="$t('auth.messageText')"
                  v-model="contact.message"
                  class="form-control msg d-block w-100"
                ></textarea>
              </b-col>
              <b-col md="12" class="mt-4">
                <div class="d-flex justify-content-center">
                  <b-button
                    variant="primary"
                    type="submit"
                    class="px-5 py-2 iq-border-radius-5"
                    >{{ $t("auth.send") }}</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import { website } from "@/axios";
export default {
  name: "contact us",
  mounted() {},
  methods: {
    async contactUs() {
      try {
        const { data } = await website().post(
          "customer/contact-us",
          this.contact
        );
        core.showSnackbar("success", data.message);
        this.$refs.form.reset();
      } catch (error) {
        core.showSnackbar("error", error.response.data.message);
      }
    },
  },
  data() {
    return {
      contact: {
        subject: "",
        email: "",
        message: "",
      },
    };
  },
};
</script>
